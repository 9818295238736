<template>
	<div>
		<van-nav-bar
			title="用戶協議"
			left-arrow
			fixed
			class="qjc-nav-bar"
			@click-left="$router.back()"
		/>
		
		<div class="agreement">
			<h2 class="qjc-c-dark qjc-fts-32">米因健康大數據體檢平台用戶協議</h2>
			<ul class="qjc-texta-l qjc-c-dark">
				<li>
					<h4>一、特別提示</h4>
					<p>在此特別提醒您（用戶）在註冊成為米因健康大數據用戶之前，請<span class="qjc-c-primary">認真閱讀</span>本《米因健康大數據體檢平台用戶協議》（以下簡稱“協議”），<span class="qjc-c-primary">确保您充分理解本协议中</span>各條款。請您審慎閱讀並選擇接受或不接受本協議。除非您接受本協議所有條款，否則您無權註冊、登錄或使用本協議所涉服務。<span class="qjc-c-primary">您的註冊、登錄、使用等行為將視為對本協議的接受，並同意接受本協議各項條款的約束。</span></p>
					<p>本協議約定米因健康大數據與用戶之間關於“米因健康大數據”服務（以下簡稱“服務”）的權利義務。“用戶”是指註冊、登錄、使用本服務的個人。本協議可由米因健康大數據隨時更新，更新後的協議條款壹旦公布即代替原來的協議條款，恕不再另行通知，用戶可隨時查閱最新版協議條款。在修改協議條款後，如果用戶不接受修改後的條款，請立即停止使用米因健康大數據提供的服務，用戶繼續使用米因健康大數據提供的服務將被視為接受修改後的協議。</p>
				</li>
				<li>
					<h4>二、賬號註冊</h4>
					<p>1、用戶在使用本服務前需要註冊一個“米因健康大數據”賬號。“米因健康大數據”賬號應當使用手機號碼綁定註冊，請用戶使用尚未與“米因健康大數據”賬號綁定的手機號碼，以及未被米因健康大數據根據本協議封禁的手機號碼註冊“米因健康大數據”賬號。米因健康大數據可以根據用戶需求或產品需要對賬號註冊和綁定的方式進行變更，而無須事先通知用戶。</p>
					<p>2、如果註冊申請者有被米因健康大數據封禁的先例或涉嫌虛假註冊及濫用他人名義註冊，及其他不能得到許可的理由， 米因健康大數據將拒絕其註冊申請。</p>
					<p>3、鑒於“米因健康大數據”賬號的綁定註冊方式，您同意並允許米因健康大數據在註冊時將您的手機號碼及手機設備識別碼等信息用於註冊。</p>
					<p>4、在用戶註冊及使用本服務時，米因健康大數據需要搜集能識別用戶身份的個人信息以便米因健康大數據可以在必要時聯系用戶，以及為用戶提供健康評估、風險預測等服務。米因健康大數據搜集的信息包括但不限於用戶的姓名、性別、居住地、身高、體重、出生日期、體型信息、生化檢查指標等；米因健康大數據同意對這些信息的使用將受限於本用戶協議第十條第3款用戶個人隱私信息保護相關條款的約束。</p>
				</li>
				<li>
					<h4>三、使用服務</h4>
					<p>1、用戶充分理解並認可米因大數據健康分析及評估產品為基於常規人體數據所做的大數據個人健康風險分析及預測，不涉及任何需專業醫療資質的診斷及處方，所有分析報告、結果及方案等輸出內容均為健康諮詢相關的參攷內容與建議，用戶須根據自身情况採用，或進一步諮詢專業的醫療人員。</p>
					<p>2、用戶通過常規流程接收到大資料分析及預測等報告相關內容時，即視為產品已完成交付，且用戶已消費完畢，米因及相關服務平臺均不接受任何退款要求及相關申訴。</p>
				</li>
			</ul>
		</div>
		
		<!-- 底部是否同意 -->
		<!-- <van-tabbar safe-area-inset-bottom class="footer" v-model="isAgree">
		  <van-tabbar-item :to="{ name : 'login', params: { isAgree: false }}" name="0">不同意</van-tabbar-item>
		  <van-tabbar-item :to="{ name : 'login', params: { isAgree: true }}" name="1">同意</van-tabbar-item>
		</van-tabbar> -->
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, Tabbar, TabbarItem } from 'vant'
	Vue.use(NavBar)
	   .use(Tabbar)
	   .use(TabbarItem);
	
	export default{
		name: 'registAgreement',
		data (){
			return {
				isAgree: ""//占位而已，无用
			}
		}
	}
</script>

<style lang="scss" scoped>
	.agreement{
		margin-top: 0.88rem;
		padding: 0.39rem 0.3rem;
		padding-bottom: 1rem;
		
		ul{
			margin-top: 0.38rem;
			
			h4{
				margin-bottom: 0.11rem;
			}
			p{
				line-height: 0.44rem;
				text-indent: 2em;
				margin-bottom: 0.36rem;
			}
		}
	}
	.footer{
		height: 0.88rem;
		line-height: 0.88rem;
		border-top: 0.01rem solid #CCCCCC;
		padding: 0.1rem 0;
		
		.van-tabbar-item{
			font-size: 0.28rem;
			color: #40444D;
			font-weight: bold;
			
			&:not(:last-child){
				border-right: 0.01rem solid #CCCCCC;
			}
		}
	}
</style>
